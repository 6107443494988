import React, { MutableRefObject } from 'react';
import { EditorState, convertToRaw, convertFromRaw, Modifier } from 'draft-js';
import type { EditorProps } from 'draft-js';
import EditorUtils from '@draft-js-plugins/utils';
import Editor from '@draft-js-plugins/editor';
import createStaticToolbarPlugin, { Separator } from '@draft-js-plugins/static-toolbar';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import {
  ItalicButton,
  BoldButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from '@draft-js-plugins/buttons';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { Observable } from 'rxjs/Observable';

import createLinkPlugin from './anchor-plugin';
import URLUtils from './anchor-plugin/utils/url-utils';
import createMentionPlugin from './mention-plugin';
import { createKeyboardShortcutsPlugin } from './keyboard-shortcuts-plugin';
import { createLinkPreviewPlugin } from './link-preview-plugin';
import { fetchMentions } from '../../utils/fetch-mentions';
import i18n from '../../../../../i18n';
import type { LinkPreview, User } from '../../../../types/objects';

// Plugin that allows to add links
const linkPlugin = createLinkPlugin({
  // @ts-expect-error
  theme: {
    input: 'Form__control',
    inputInvalid: 'Form__control--invalid',
  },
  placeholder: i18n.t('common:form_input_markup_link_placeholder'),
});

// Toolbar on top of editor
const staticToolbarPlugin = createStaticToolbarPlugin({
  theme: {
    buttonStyles: {
      buttonWrapper: 'Form__MarkupEditor__Toolbar__Button__Container',
      button: 'Form__MarkupEditor__Toolbar__Button',
      active: 'Form__MarkupEditor__Toolbar__Button--active',
    },
    toolbarStyles: {
      toolbar: 'Form__MarkupEditor__Toolbar',
    },
  },
});

// Plugin showing toolbar next to the selected text
const inlineToolbarPlugin = createInlineToolbarPlugin({
  theme: {
    toolbarStyles: {
      toolbar: 'MarkupEditor__InlineToolbar',
    },
    buttonStyles: {
      buttonWrapper: 'MarkupEditor__InlineToolbarButtonWrapper',
      button: 'MarkupEditor__InlineToolbarButton',
      active: 'MarkupEditor__InlineToolbarButton--active',
    },
  },
});

// Plugin which allows mentioning in editor
// @ts-ignore
const mentionPlugin = createMentionPlugin({
  supportWhitespace: true,
  theme: {
    mentionSuggestions: 'Form__MarkupEditor__Mentions__Dropdown',
    mentionSuggestionsEntry: 'Form__MarkupEditor__Mentions__Dropdown__Item',
    // eslint-disable-next-line max-len
    mentionSuggestionsEntryFocused: 'Form__MarkupEditor__Mentions__Dropdown__Item Form__MarkupEditor__Mentions__Dropdown__Item--focused',
    mentionSuggestionsEntryText: 'Form__MarkupEditor__Mentions__Dropdown__Item__Name',
  },
  mentionComponent: ({ mention }: { mention: { full_name: string } }) => (
    <span className="Form__MarkupEditor__Mention">{mention.full_name}</span>
  ),
});

const keyboardShortcutsPlugin = createKeyboardShortcutsPlugin();
const linkPreviewPlugin = createLinkPreviewPlugin();

export type MarkdownEditorOwnProps = {
  value: string;
  target?: any,
  disabled?: boolean;
  onPaste?: (e: ClipboardEvent) => void;
  onChange: (newValue: string) => void;
  addMentionRef?: MutableRefObject<() => void>;
  dispatchUpdatesOnChange?: boolean;
  useLinkPreview?: boolean;
  onLinkPreviewChanged?: (linkPreview: LinkPreview) => void;
};

type State = {
  isMentionsOpen: boolean;
  suggestions: User[];
  editorState: EditorState;
};

class MarkdownEditor extends React.Component<MarkdownEditorOwnProps, State> {
  static props: MarkdownEditorOwnProps;

  editor: any;

  pasteListener: ((e: ClipboardEvent) => void) | undefined;

  setEditorReference: (ref?: any) => any;

  constructor(props: MarkdownEditorOwnProps) {
    super(props);

    const initialValue = (props.value || '');

    this.state = {
      isMentionsOpen: false,
      suggestions: [],
      editorState: EditorState.createWithContent(convertFromRaw(markdownToDraft(initialValue, {
        preserveNewlines: true,
        // @ts-expect-error
        escapeMarkdownCharacters: false,
      }))),
    };

    // @ts-expect-error
    this.search$ = null;

    this.setEditorReference = (editor) => (this.editor = editor);
    this.handleBlur = this.handleBlur.bind(this);
    // @ts-expect-error
    this.pasteListener = null;
  }

  componentDidMount() {
    // Fixes issue with draft-js
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    setTimeout(this.handleFocus, 0);

    if (this.props.onPaste) {
      this.pasteListener = (e: ClipboardEvent) => {
        e.preventDefault();
        const editorRef = this.editor.getEditorRef();

        if (document.activeElement === editorRef.editor) {
          this.props.onPaste!(e);
        }
      };

      document.addEventListener('paste', this.pasteListener);
    }

    if (this.props.target) {
      Observable
        // @ts-expect-error
        .create((observer) => (this.search$ = observer))
        .debounceTime(250)
        // @ts-expect-error
        .filter((search) => search.length > 0 && search.length <= 20)
        // @ts-expect-error
        .map((search) => fetchMentions(search, this.props.target))
        // @ts-expect-error
        .subscribe(async (promise) => {
          const { data = [] } = await promise;
          // @ts-expect-error
          const result = data.filter((user) => user.id !== this.props.loggedUserId);
          this.setState({ suggestions: result });
        });
    }
  }

  componentWillUnmount() {
    if (this.pasteListener) document.removeEventListener('paste', this.pasteListener);
  }

  handleFocus = () => this.editor?.focus();

  handleBlur() {
    const { value, onChange } = this.props;
    const { editorState } = this.state;

    const content = draftToMarkdown(convertToRaw(editorState.getCurrentContent()), {
      preserveNewlines: true,
      escapeMarkdownCharacters: false,
    });

    if (value === content) return;

    onChange(content);
  }

  triggerAddMention = () => {
    const { editorState } = this.state;

    this.handleFocus();

    const prevSelection = editorState.getSelection();
    const anchorKey = prevSelection.getAnchorKey();
    const prevContent = editorState.getCurrentContent();
    const selectedBlock = prevContent.getBlockForKey(anchorKey);
    const selectedBlockOfText = selectedBlock.getText();

    const selectedText = selectedBlockOfText.slice(
      prevSelection.getStartOffset() - 1,
      prevSelection.getEndOffset(),
    );

    // There has to be a whitespace before @ sign. So we are checking if it's already there and adding if necessary.
    const shouldAddWhitespace = !selectedText.startsWith(' ') && !!selectedBlockOfText.length;

    const selectionOffset = prevSelection.getStartOffset() + (shouldAddWhitespace ? 2 : 1);

    const newSelection = prevSelection.merge({
      focusOffset: selectionOffset,
      anchorOffset: selectionOffset,
    });

    const newContent = Modifier.insertText(
      prevContent,
      prevSelection,
      `${shouldAddWhitespace ? ' ' : ''}@`,
    );

    const newState = EditorState.push(editorState, newContent, 'insert-characters');

    const stateCorrectedSelection = EditorState.forceSelection(newState, newSelection);

    this.setState({
      editorState: stateCorrectedSelection,
    });
  };

  handlePastedText: EditorProps['handlePastedText'] = (text) => {
    const withHTTP = text.startsWith('http') ? text : `https://${text}`;
    const isUrl = URLUtils.isUrl(withHTTP);
    const state = this.editor.getEditorState();
    const isCollapsed = state.getSelection().isCollapsed();

    if (!isUrl || isCollapsed) return 'not-handled';

    this.setState({
      editorState: EditorUtils.createLinkAtSelection(state, text),
    });

    return 'handled';
  };

  handleChange: EditorProps['onChange'] = (editorState) => {
    const { dispatchUpdatesOnChange, onChange } = this.props;

    this.setState({ editorState });

    if (dispatchUpdatesOnChange) {
      const content = draftToMarkdown(convertToRaw(editorState.getCurrentContent()), {
        preserveNewlines: true,
        escapeMarkdownCharacters: false,
      });

      onChange(content);
    }
  };

  render() {
    const { isMentionsOpen, suggestions, editorState } = this.state;
    const {
      target, addMentionRef, onLinkPreviewChanged, useLinkPreview, disabled = false,
    } = this.props;
    const { Toolbar } = staticToolbarPlugin;
    const { InlineToolbar } = inlineToolbarPlugin;
    const { LinkPreview } = linkPreviewPlugin;

    if (addMentionRef) addMentionRef.current = this.triggerAddMention;

    const plugins = [
      staticToolbarPlugin,
      inlineToolbarPlugin,
      linkPlugin,
      keyboardShortcutsPlugin,
    ];

    if (target) {
      plugins.push(mentionPlugin);
    }

    if (useLinkPreview) {
      plugins.push(linkPreviewPlugin);
    }

    return (
      <div className="Form__control Form__MarkupEditor Form__control--grow" onClick={this.handleFocus}>
        {useLinkPreview && <LinkPreview onPreviewChanged={onLinkPreviewChanged} />}
        <Editor
          readOnly={disabled}
          editorState={editorState}
          onChange={this.handleChange}
          plugins={plugins}
          handlePastedText={this.handlePastedText}
          ref={this.setEditorReference}
          stripPastedStyles
          customStyleMap={{
            CODE: {
              padding: '4px 8px',
              backgroundColor: '#F6F6F6',
              color: '#FF3b30',
              fontFamily: 'monospace',
            },
          }}
          onBlur={this.handleBlur}
        />
        {target && (
          <mentionPlugin.MentionSuggestions
            open={isMentionsOpen}
            // @ts-expect-error
            onOpenChange={(isOpen) => this.setState({ isMentionsOpen: isOpen, suggestions: [] })}
            suggestions={suggestions}
            // @ts-expect-error
            onSearchChange={({ value }) => this.search$.next(value)}
          />
        )}
        <Toolbar>
          {(externalProps) => (
            <>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              {/* @ts-expect-error */}
              <Separator {...externalProps} />
              <HeadlineOneButton {...externalProps} />
              <HeadlineTwoButton {...externalProps} />
              {/* @ts-expect-error */}
              <Separator {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
              {/* @ts-expect-error */}
              <Separator {...externalProps} />
              <BlockquoteButton {...externalProps} />
            </>
          )}
        </Toolbar>
        <InlineToolbar>
          {(externalProps) => (
            <>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <linkPlugin.LinkButton {...externalProps} />
            </>
          )}
        </InlineToolbar>
      </div>
    );
  }
}

export default MarkdownEditor;
